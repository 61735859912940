/* BEGIN:Sign in and Sign up scss */

.registration {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;

    &__middleTransparentContainer {
        width: 30rem;
        display: flex;
        flex-flow: row;
        width: 100%;
        height: 100vh;
        max-width: 1920px;
        padding-top: 0px;
        padding-bottom: 0px;
        align-items: flex-start;
        position: relative;

        &__upper {
            display: flex;
            align-items: center;
            position: absolute;
            left: 80px;
            top: 50px;
            right: auto;
            bottom: auto;
            z-index: 60;
            background-image: none;
            background-size: auto;
            background-repeat: repeat;

            img {
                width: 2.5rem;
                height: 2.5rem;
            }

            h4 {
                color: #f7f7f7;
                margin-left: 0.5rem;
            }
        }
    }

    &__formContainer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 100%;
        padding-bottom: 54px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }
}

.home {


    form {
        width: 100%;
        padding: 0;
        max-width: 400px;
        align-items: center;
        margin-top: 40px;
    }

    .input-container {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin-top: 0.5rem;
        box-sizing: border-box;

        input {
            margin-bottom: 24px;
            padding: 30px 28px;
            border: 1px none #000;
            border-radius: 12px;
            background-color: #f1f3f6;
            font-size: 18px;
            max-height: 3rem;

            &::placeholder {
                color: #929eaa;
            }
        }

        span {
            font-weight: 600;
            margin-bottom: 16px;
        }
    }

    .linkToRedirect {
        margin-top: 0.5rem;
        cursor: pointer;
    }


    .navbar-brand img {

        object-fit: contain;
        margin-top: 0;
        height: 2.5rem;
        width: 2.5rem;

    }

    .header .container {
        background-color: white;
        color: black;
        font-size: 80%;
    }

    .navbar-toggler-icon {

        background-image: url(https://assets.website-files.com/61e3aa555a9149f4f1e68fe4/61e3aa555a9149100de690c2_Group%2090.svg);
    }

    .navigation-center {
        display: flex;
        width: auto;
    }

    .navigation-right {
        display: flex;
        width: auto;
        justify-content: flex-end;
        align-items: center;
    }

    .navigation-left {
        display: flex;
        width: auto;
        justify-content: flex-start;
        align-items: left;
        color: #000;
    }

    .reset {
        align-items: center;
        width: 100%;
        height: 100%;

    }

    .main-navigations-container {
        position: relative;
    }

    .navigation-left {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: auto;
        justify-content: space-between;
        align-items: center;
        padding: 16px 25px;
    }

    .navigation-center {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: auto;
    }

    .navigation-right {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: auto;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .nav-link {
        margin-right: 0px;
        margin-left: 0px;
        -webkit-transition: all 200ms ease;
        transition: all 200ms ease;
        color: #04121f;
        font-size: 16px;
    }

    a {
        color: #145aff;
        text-decoration: none;
        background-color: transparent;
    }


    .button {
        padding: 20px 25px;
        line-height: 120%;
        font-weight: 700;
        width: 70%;
        max-height: 3rem;
        border-radius: 12px;
        border: none;
        color: white;
        cursor: pointer;
        font-size: 18px;

        .navigation-button {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            background-color: #0d0e10;
        }
    }

    .testimonial-item {
        overflow: hidden;
        max-width: 45vh;
        max-height: 25vh;
        margin-right: 40px;
        padding: 32px 28px 50px;
        border-radius: 12px;
        font-size: 18px;

        &.sign-up-page {
            position: absolute;
            left: 4.5%;
            top: auto;
            right: auto;
            bottom: 4%;
            z-index: 20;
            background-color: #fff;
        }

    }

    .testimonial-image-and-name {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 22px;

        justify-content: flex-start;

        align-items: center;
    }

    .testimonial-image {
        overflow: hidden;
        width: 56px;
        height: 56px;
        margin-right: 20px;
        flex: 0 0 auto;
        border-radius: 100%;


        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        img {
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
        }

    }

    h5 {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #0d0e10;
        font-size: 18px;
        line-height: 144%;
        font-weight: 700;
    }

    ._16-px {
        flex: 0 0 auto;
        font-size: 16px;
    }

    .password-wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        max-width: 680px;
        margin: 60px auto 0px;
        padding-bottom: 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .sign-up-image {
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
        }
    }

    // span {
    //     font-size: 0.9rem;
    //     opacity: 1;
    //     transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    //     transform-style: preserve-3d;
    //     margin-top: 0.2rem;
    // }

    span#resetspan {
        margin-top: 1rem;
    }

    .password-field-label.reset {
        color: #0d0e10;
    }

    .password-field-label {
        width: 70%;
        margin-bottom: 0.4rem;
        font-size: 1rem;
        font-weight: 500;
        text-align: left;
    }

    .button:hover {
        background-color: #1ebd53;
    }

    .contact-form-text-field {
        margin-bottom: 24px;
        padding: 30px 28px;
        border: 1px none #000;
        border-radius: 12px;
        background-color: #f1f3f6;
        font-size: 18px;
    }

    .w-button {
        display: inline-block;
        padding: 9px 15px;
        background-color: #c9c8cf;
        border: 0;
        line-height: inherit;
        text-decoration: none;
        cursor: pointer;
        border-radius: 12px;
        width: auto;
        margin-top: 0;
    }

    .forgot-password {
        margin-top: 16px;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-transition: color 200ms ease;
        transition: color 200ms ease;
        color: #40454f;
        font-size: 16px;
        text-decoration: underline;
    }

    .linkToRedirect {
        margin-top: 0.5rem;
        cursor: pointer;
    }


    @media screen and(max-width: 1132px) {
        .registration__middleTransparentContainer__upper {
            left: 40px;
            top: 30px;
            width: 158px;
            height: 28px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .sign-up-image {
            display: none;
        }

        .registration__middleTransparentContainer {
            height: auto;

            align-items: center;

        }

        .registration__formContainer {
            padding-top: 157px;
            padding-bottom: 138px;
        }

        .registration__formContainer {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            height: 100%;
            padding-bottom: 54px;

            flex-direction: column;

            justify-content: center;

            align-items: center;

            flex: 1;
        }

        h2 {
            font-size: 35px;
        }
    }

    @media screen and (max-width: 479px) {
        body {
            font-size: 16px;
            font-family: 'Space Grotesk', sans-serif;
            color: #40454f;
            font-size: 18px;
            line-height: 155%;
        }

        .registration {
            overflow: hidden;

        }

        .registration__middleTransparentContainer {
            width: 90%;
        }

        .registration__middleTransparentContainer__upper {
            display: none;
        }

        h2 {
            font-size: 32px;
        }

        .linkToRedirect {
            text-align: center;
        }

        .sign-in-form {
            text-align: left;
            margin-left: 1.5rem;
        }

        .contact-form-field-label {
            margin-bottom: 10px;
            font-size: 16px;
            padding-left: 16px;

        }

        .registration__formContainer {
            max-width: 300px;
            text-align: center;
        }

        .input-container {
            display: flex;
            flex-flow: column;

            font-size: 18px;
            box-sizing: border-box;
            text-align: left;
        }

        .button {

            line-height: 0.9rem;
        }

        .navbar-brand {
            font-size: 16px;
        }

        .navbar-brand img {

            object-fit: contain;
            margin-top: 0;
            height: 1.5rem;
            width: 1.5rem;


        }

        .password-wrapper {
            margin-top: 100px;
            align-items: center;

        }

        .password-text {
            max-width: 310px;

        }

        .resetinput {
            width: 50%;
        }

        .button.navigation-button.mobile {
            justify-content: left;
            font-size: 16px;
            text-align: left;
            padding: 12px;
            border-radius: 12px;
        }

        .navigation-right.navbar-nav {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }

    }

}

/* END:Sign in and Sign up scss */


body {
    font-size: 62.5%;
    background-color: white;
    font-family: 'Space Grotesk', sans-serif;

}

// .wrapper.navigation-wrapper {
//     padding-top: 20px;
//     padding-bottom: 20px;
// }

// .wrapper {
//     position: relative;
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     width: 85%;
//     max-width: 1280px;
//     margin-right: auto;
//     margin-left: auto;
//     padding-top: 80px;
//     padding-bottom: 80px;
//     -webkit-box-pack: justify;
//     -webkit-justify-content: space-between;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: center;
//     -webkit-align-items: center;
//     -ms-flex-align: center;
//     align-items: center;
// }

// .w-nav-link {
//     position: relative;
//     display: inline-block;
//     vertical-align: top;
//     text-decoration: none;
//     color: #222222;
//     padding: 20px;
//     text-align: left;
//     margin-left: auto;
//     margin-right: auto;
// }

// a {
//     color: #145aff;
//     text-decoration: none;
//     background-color: transparent;
// }

// .w-inline-block {
//     max-width: 100%;
//     display: inline-block;
//     margin-right: 2rem;
//     font-size: 18px;
// }

// .nav-link.login.desktop {
//     padding-right: 15px;
//     padding-left: 15px;
// }

// .nav-link.login {
//     -webkit-box-flex: 0;
//     -webkit-flex: 0 0 auto;
//     -ms-flex: 0 0 auto;
//     flex: 0 0 auto;
//     font-weight: 500;
// }

// .registration__middleTransparentContainer__upper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     left: 80px;
//     top: 50px;
//     right: auto;
//     bottom: auto;
//     z-index: 60;
//     background-image: none;
//     background-size: auto;
//     background-repeat: repeat;

//     img {
//         width: 2.5rem;
//         height: 2.5rem;
//     }

//     h4 {
//         margin-left: 0.5rem;
//     }
// }


.sign-up-right {
    color: #40454f;
    font-size: 18px;
    line-height: 155%;

    h6.subheading {
        margin-top: 0px;
        margin-bottom: 16px;
        color: #145aff;
        font-size: 16px;
        line-height: 150%;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    h2 {
        margin-top: 0px;
        margin-bottom: 0;
        color: #0d0e10;
        font-size: 48px;
        line-height: 108%;
        font-weight: 700;
        letter-spacing: -0.8px;
    }

    .sign-up-heading {
        margin-bottom: 16px;
    }

    .sign-up-link {
        color: #145aff;
        font-weight: 500;
        text-decoration: none;
    }

    .sign-in-form {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .button {
        padding-right: 64px;
        padding-left: 64px;
        background-color: #145aff;
    }

}