.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - #{$sideBar-Width});
  height: $topBar-height;
  position: fixed;
  top: 0;
  right: 0;
  background-image: linear-gradient(
    to right bottom,
    rgba($color-main, 0.9),
    rgba($color-main, 1)
  );
  padding: 0 1rem;

  &__leftContainer {
    display: flex;
    flex-flow: row;
    gap: 1rem;
    width: 40%;
    height: 65%;
    &__dropDownSection {
      display: flex;
      width: 70%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: $color-background;
      padding: 0 0.5rem;
      &--icon {
        width: fit-content;
        padding-right: 1rem;
      }
      &--dropdown {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        select {
          width: 100%;
          border: none;
        }
      }
    }
    &__plusButton {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.8rem;
      background-color: $color-background;
      button {
        border: none;
        cursor: pointer;
      }
    }
  }
  &__rightContainer {
    width: 25%;
    height: 65%;
    display: flex;
    flex-flow: row;
    gap: 0.6rem;
    // justify-content: flex-end;

    &__item {
      // width: fit-content;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
      padding: 1rem 0.8rem;
      background-color: $color-background;
      border-radius: 0.5rem;
    }
    &__D {
      display: flex;
      flex-grow: 1;
      flex-flow: row;
      justify-content: center;
      height: 100%;
      align-items: center;
      background-color: $color-background;
      border-radius: 0.5rem;
    }
    &__dropDownSection {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: $color-background;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      border-radius: 1rem;
      &--icon {
        width: 2.5rem;
        height: 2.3rem;
        margin-right: 0.3rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      &--dropdown {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        select {
          width: 100%;
          border: none;
        }
      }
    }
  }
}

.side-bar-toggle{
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

@media screen and (max-width:1290px) {
  .topBar{
    width: 100%!important;
    margin-left: 80px;
    &__rightContainer{
      width: auto!important;
    }
  }

  .side-bar-toggle {
    display: block;
  }

}

@media screen and (max-width:530px) {
  .topBar {
    width: 100% !important;
    margin-left: 80px;

    &__rightContainer__item {
      display: none!important;
    }

    &__leftContainer{
      width: 100%!important;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.topBar.expand{
  width: calc(100% - 55px);
}