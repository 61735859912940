/* Spinner component*/
.spinner-wrapper{
    display: block;
    position: fixed;
    z-index: 1031; /* High z-index so it is on top of the page */
    top: 50%;
    right: 50%; /* or: left: 50%; */
    margin-top: -50px; /* half of the elements height */
    margin-right: -50px; /* half of the elements widht */
  }
  