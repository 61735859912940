/* adding the variables*/
@import "./sass/abstract/_variables.scss";

/* === Components === */
@import "./sass/components/_SideBar.scss";
@import "./sass/components/_TopBar.scss";


* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  &::after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }
  &::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }
  p {
    margin-bottom: 0;
  }
}
body {
  font-size: 62.5%;
  background-color: #f7f7f7;
  font-family: "Open Sans", sans-serif;
}

/* Begin: Reviews module */
.reviews {
  padding: 1rem;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reviews__content {
  width: 100%;
  padding-top: 5rem;
  display: flex;
  input {
    border: 1px solid lightgray;
  }
}
.reviews__content__anotherForm {
  width: 100%;
  margin-top: 2rem;
  p {
    font-size: 0.8rem;
    font-weight: 300;
  }
  h4{
    display: inline-block;
    padding-right: 1rem;
  }
}
.reviews__content__anotherForm__input {
  display: flex;
  flex-flow: column;
  margin-top: 1.5rem;
  input {
    margin-top: 0.5rem;
  }
}
.reviews__content__anotherForm__input--label {
  font-size: 0.75rem;
}
.reviews__content__anotherForm__checkboxContainer {
  width: 100%;
  display: flex;
  height: 3rem;
  align-items: center;
  span {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
}
.reviews__content__anotherForm__headingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  h1 {
    margin-right: 1rem;
    font-size: 1.5rem;
    font-weight: 400;
  }
}
.reviews__content__outer {
  flex: 1;
  padding: 0 3.5rem;
}
.reviews__content__linksForm {
  padding-top: 1rem;
}
.reviews__content__linksForm__heading {
  width: 100%;
  display: flex;
  justify-content: center;
  h1 {
    font-size: 1.8rem;
  }
}
.reviews__content__linksForm__upperForm--checkboxContainer {
  display: flex;
  height: 3rem;
  align-items: center;
}
.reviews__content__linksForm__upperForm--linksContainer {
  display: flex;
  flex-flow: column;
  span {
    color: #0b69ce;
    cursor: pointer;
    font-size: 0.8rem;
  }
}
.reviews__content__sidebar {
  height: 100%;
  background-color: white;
  padding-top: 1rem;
}
.reviews__content__sidebar__item {
  width: 100%;
  height: 3rem;
  cursor: pointer;
  display: flex;
  padding-left: 1rem;
  align-items: center;
}
.reviews__content__sidebar__item--icon {
  font-size : 24px;
}
.reviews__content__sidebar__item--name {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-left: 1rem;
}
.inputLinksForm {
  width: 100%;
  border: none;
  height: 3rem;
  border-radius: 0.5rem;
  padding-left: 1rem;
  box-shadow: 0.1rem 0.5rem 1rem rgba(0, 0, 0, 0.3);
}
.checkboxText {
  margin-left: 0.5rem;
  color: gray;
}
.grayborder {
  width: 100%;
  border-bottom: 3px solid lightgray;
  margin-top: 1rem;
}

.smallButton {
  padding: 0.5rem 1rem;
  background-color: #102961;
  border-radius: 0.5rem;
  color: white;
  font-size: 1.2rem;
}
.switch {
  position: relative;
  display: inline-block;
  width: 2.8rem;
  height: 1.2rem;
  vertical-align: super;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 0.8rem;
    width: 0.8rem;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
input {
  &:checked {
    + {
      .slider {
        background-color: #102961;
        &:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
    }
  }
  &:focus {
    + {
      .slider {
        box-shadow: 0 0 1px #102961;
      }
    }
  }
}
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}
/* END : Review module css ends here */

.page {
  box-sizing: border-box;
  background: #ffffff;
  height: 100vh;
  width: 100%;
}
.subScreen {
  width: calc(100% - 20%);
  margin-left: 20%;
  height: auto;
  min-height: 92vh;
  margin-top: 4rem;
  background-color: #f7f6fe;
}
.subScreen.expand{
  margin-left: 55px;
  width: calc(100% - 55px);
}
.side-bar-toggle {
  background-color: transparent;
  border: none;

  cursor: pointer;
  display: none;

}

@media screen and (max-width:1290px) {
  .subScreen{
    margin-left: 0px;
    width: 100%;
  }
  .side-bar-toggle {
    display: block;
  }
}


/* BEGIN: top bar css*/
.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20%);
  height: 4rem;
  position: fixed;
  top: 0;
  right: 0;
  background-image: linear-gradient(
    to right bottom,
    rgba(16, 41, 97, 0.9),
    #102961
  );
  padding: 0 1rem;
  z-index: 1000;
}
.topBar__leftContainer {
  display: flex;
  flex-flow: row;
  gap: 1rem;
  width: 40%;
  height: 65%;
}
.topBar__leftContainer__dropDownSection {
  display: flex;
  width: 70%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 0 0.5rem;
}
.topBar__leftContainer__dropDownSection--icon {
  width: fit-content;
  padding-right: 1rem;
}
.topBar__leftContainer__dropDownSection--dropdown {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  select {
    width: 100%;
    border: none;
  }
}
.topBar__leftContainer__plusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background-color: #ffffff;
  button {
    border: none;
    cursor: pointer;
  }
}
.topBar__rightContainer {
  width: 25%;
  height: 65%;
  display: flex;
  flex-flow: row;
  gap: 0.6rem;
}
.topBar__rightContainer__item {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 1rem 0.8rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.topBar__rightContainer__D {
  display: flex;
  flex-grow: 1;
  flex-flow: row;
  justify-content: center;
  height: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.topBar__rightContainer__dropDownSection {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 1rem;
}
.topBar__rightContainer__dropDownSection--icon {
  width: 2.5rem;
  height: 2.3rem;
  margin-right: 0.3rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.topBar__rightContainer__dropDownSection--dropdown {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  select {
    width: 100%;
    border: none;
  }
}

/*END : top bar css*/

/*BEGIN AdditemPage.js also used in reviewlist.js css*/
.addStore {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.addStore__message {
  font-size: 1.2rem;
  margin-bottom: 3rem;
  span {
    color: rgba(0, 0, 255, 0.7);
    cursor: pointer;
    &:hover {
      color: blue;
      text-decoration: underline;
    }
  }
}
.addStore__button {
  padding: 0.8rem 2.4rem;
  background-color: rgba(16, 41, 97, 0.9);
  color: #ffffff;
  display: flex;
  flex-flow: row;
  gap: 1rem;
  border-radius: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: #102961;
  }
  img {
    width: auto;
    height: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}

/* END : AddItemPage.js css*/

/*START : locationsetting and Addstoreform.js css*/
.addStore2 {
  width: 100%;
  height: inherit;
  display: flex;
  flex-flow: column;
  padding: 1rem;
}
.addStore2__heading {
  font-size: 2rem;
  text-transform: capitalize;
  h3 {
    font-weight: 500;
  }
}
.addStore2__subHeading {
  p {
    font-size: 1rem;
    span {
      color: rgba(0, 0, 255, 0.7);
      cursor: pointer;
      &:hover {
        color: blue;
        text-decoration: underline;
      }
    }
  }
}
.addStore2__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}
.addStore2__buttons__button {
  background-color: rgba(16, 41, 97, 0.9);
  display: flex;
  flex-flow: row;
  padding: 0.5rem 2rem;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: #102961;
  }
  p {
    font-size: 1rem;
    color: #ffffff;
  }
}
.addStore2__buttons__button--img {
  width: auto;
  height: 1.6rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.addStore2__searchOnGoogle {
  margin-top: 1rem;
  display: flex;
  box-shadow: 0 0.1rem 0.3rem #c9c8cf;
  border-radius: 0.5rem;
  height: 5vh;
}
.addStore2__searchOnGoogle__searchBox {
  display: flex;
  gap: 0.5rem;
  flex-flow: row;
  padding: 1.2rem 1rem;
  border: 1px solid #c9c8cf;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.5rem 0.5rem 0;
  border-left: none;
  width: -webkit-fill-available;
  background-color: #ffffff;
  input {
    flex-grow: 1;
    border: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #707c97;
    }
  }
  &:focus-visible {
    outline: none;
  }
}
.addStore2__searchOnGoogle__searchBox__img {
  width: auto;
  padding: 0.5rem;
  border: 1px solid #c9c8cf;
  border-radius: 0.5rem 0 0 0.5rem;
  background-color: #ffffff;
  border-right: none;
}
.search-location-input {
  display: flex;
  justify-content: center;
  > div {
    width: 30%;
  }
}
.addStore2__formContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  form {
    margin-top: 1rem;
    width: 35%;
    .firstRow {
      display: flex;
      margin-top: 0.6rem;
      justify-content: space-between;
      flex-flow: row;
      width: 100%;
      .uploadButton {
        font-size: 1rem;
        background-color: rgba(16, 41, 97, 0.9);
        width: 50%;
        padding: 0.3rem 1rem;
        border-radius: 3rem;
        color: #ffffff;
        text-align: center;
        &:hover {
          background-color: #102961;
          cursor: pointer;
        }
      }
    }
    .firstRow__box {
      width: 48%;
      label {
        font-size: 0.8rem;
      }
      input {
        font-size: 1rem;
        width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid #c9c8cf;
        box-shadow: 0 0.1rem 0.3rem #c9c8cf;
        border-radius: 0.5rem;
      }
      select {
        font-size: 1rem;
        width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid #c9c8cf;
        box-shadow: 0 0.1rem 0.3rem #c9c8cf;
        border-radius: 0.5rem;
      }
    }
    .addressRow {
      width: 100%;
      margin-top: 0.6rem;
      label {
        font-size: 0.8rem;
      }
      input {
        font-size: 1rem;
        width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid #c9c8cf;
        box-shadow: 0 0.1rem 0.3rem #c9c8cf;
        border-radius: 0.5rem;
      }
    }
    .location__box {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .location__box--button {
      cursor: pointer;
      gap: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      color: #ffffff;
      background-color: #ff3366;
      border-radius: 0.4rem;
    }
  }
}


/*END : locationsetting and Addstoreform.js css*/