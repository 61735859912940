$color-main: #102961;
$color-heading: #231f20;
$color-background: #ffffff;
$color-learn-more: #4499f4;
$start-compaign-color: #ff3366;
$pop-up-background: #efeff1;

$sideBar-Width: 20%;
$topBar-height: 4rem;

// $sideBar-Width: 20%; 18rem

$navbar-height: 63px;