.sideBar {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: $sideBar-Width;
  background-color: $color-main;
  display: flex;
  flex-flow: column;
  list-style: none;
  overflow: hidden;
  padding-top: 2rem;
  transition: width 0.1s ease-in-out;
  z-index: 100;

  &__logo {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15vh;
    margin-bottom: 1rem;
    background: transparent;
    box-sizing: border-box;
    color: $color-background;

    &__img {
   
      height: auto;
      display: flex;
      padding-right: 1rem;
      img {
        width:100%;
      
        height: 100%;
      }
    }
  }
  &__item {
    cursor: pointer;
    height: 6.5vh;
    display: flex;
    flex-flow: row;
    gap: 1rem;
    align-items: center;
    padding-left: 20%;
    text-decoration: none;
    // justify-content: center;

    &--icon {
      display: inline-block;
    }
    &--name {
      width: calc(100% - 10%);
      padding-left:8px;
      vertical-align: top;
      color: $color-background;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
  &__PlanButton {
    margin-top: 1rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    margin-bottom: 1rem;
    box-sizing: border-box;
    color: $color-background;

    &__button {
      background-color: $start-compaign-color;
      color: white;
      padding: 0.5rem 3rem;
      border-radius: 1rem;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  &__LogoutButton {
    margin-top: 1rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10vh;
    margin-bottom: 1rem;
    box-sizing: border-box;
    color: $color-background;
    &__button {
      cursor: pointer;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      background-color: $color-background;
      color: $color-heading;
      padding: 0.5rem 3rem;
      border-radius: 1rem;
      border: none;
      text-transform: uppercase;
      img {
        padding-right: 1rem;
      }
    }
  }
}

.collapse:not(.show){
  display: none;
}
.side-bar-large-toggle{
  background-color: transparent;
  border: none;
  position: absolute;
  right: 2rem;
  cursor: pointer;
  right: 2rem;

 
}

.side-bar-large-toggle.shrink{
  right:  1rem;
  top: 2rem;
  transform: rotate(178deg);
}


@media screen  and (max-width:1290px){
  .sideBar{
    transition: all 400ms ease 0s;
    margin-top: $topBar-height;
    min-width: 253px;
    left: -253px;
    &__item {
      padding-left: 10%;
    }

  }

  .side-bar-large-toggle{
    display: none;
  }
  
}

.sideBar.shrink{
  width: 55px;
  padding-top: 5rem;

  .sideBar__logo{
     display: none;
  }
  .sideBar__item--name {
    display: none;
  }
  .sideBar__LogoutButton{
    display: none;
  }
  .sideBar__PlanButton{
    display: none;
  }
  
}